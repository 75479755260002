import React, { useState } from 'react';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    Checkbox,
    Text,
    useDisclosure
} from '@chakra-ui/react'
import { Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from "react-redux";
import EssayGeneration from './essayGeneration';
import {
    abortGeneration,
    appendResponseTrunk,
    clearResponse,
    handleErrorResponse,
    selectToeflData,
    updateIsStreaming,
} from 'redux/toeflSlice';
import { useTranslation } from 'react-i18next';
import {
    cmApi,
    useGetBalanceQuery,
    useGetUserPreferencesQuery,
    useReportMutation,
    useUpdateUserPreferencesMutation
} from "../services/cmApi";
import AuthService from "../services/auth.service";
import { getChargeAmount, isGenTypeGeneration } from "./utils/common";
import { updateContent } from 'redux/toeflSlice';
import mixpanel from 'mixpanel-browser';

export default function ToeflSubmitButton(props) {
    var { genType, qid, gid, structured, ...other } = props;
    const [content, setContent] = useState([]);
    const toeflData = useSelector(selectToeflData);

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const cancelRef = React.useRef();
    const {
        isOpen: isChargeAlertOpen,
        onOpen: onChargeAlertOpen,
        onClose: onChargeAlertClose
    } = useDisclosure()
    const {
        isOpen: isSubmitAlertOpen,
        onOpen: onSubmitAlertOpen,
        onClose: onSubmitAlertClose
    } = useDisclosure()
    const [updateUserPreferencesRequest, isUpdateUserPreferencesError] = useUpdateUserPreferencesMutation();
    const [silenceChargeAlert, setSilenceChargeAlert] = React.useState(true);
    const [reportRequest, reportRequestError] = useReportMutation();
    let preferences = useGetUserPreferencesQuery({});
    let balance = useGetBalanceQuery({});

    const getChargeAlertKey = () => {
        let key = "shouldSilence_" + genType;
        return key;
    }
    const shouldShowChargeAlert = () => {
        if (toeflData.selectedExampleLevel) {
            return false;
        }

        if (preferences.status !== "fulfilled") {
            return true;
        }

        if (balance <= 200) {
            return true;
        }

        const key = getChargeAlertKey();
        if (key in preferences.data) {
            return !preferences.data[key];
        } else {
            return true;
        }
    };

    const validateEvaluationInput = () => {
        switch (genType) {
            case 'speaking_task1':
            case 'speaking_task2':
            case 'speaking_task3':
            case 'speaking_task4':
                if (!toeflData.audioFile) {
                    alert(t("empty_audio_message"));
                    return false;
                }
                break;
            case 'speaking_generation':
            case 'speaking_generation_task1':
                if (!toeflData.question) {
                    alert(t("empty_question_message"));
                    return false;
                }
                break;
            case 'speaking_generation_task2':
            case 'speaking_generation_task3':
            case 'speaking_generation_task4':
                if (!toeflData.question) {
                    alert(t("empty_question_message"));
                    return false;
                }
                break;
            case 'writing_task1':
            case 'writing_task2':
            case 'writing_task3':
                if (!toeflData.answer) {
                    alert(t("empty_answer_message"));
                    return false;
                }
                break;
            case 'writing_generation':
                if (!toeflData.question) {
                    alert(t("empty_question_message"));
                    return false;
                }
                break;
            case 'writing_generation_task1':
            case 'writing_generation_task3':
                if (!toeflData.question) {
                    alert(t("empty_question_message"));
                    return false;
                }
                break;
            default:
                console.log("Invalid genType", genType);
        }
        return true;
    }
    const prepareRequestParams = () => {
        switch (genType) {
            case 'speaking_task1':
            case 'speaking_task2':
            case 'speaking_task3':
            case 'speaking_task4':
                return {
                    type: 'speaking_' + toeflData.taskName,
                    topic: toeflData.question,
                    audio: toeflData.audioFile,
                    audioSource: toeflData.audioSource,
                    reading: toeflData.readingText,
                    listening: toeflData.listeningText,
                    example_level: toeflData.selectedExampleLevel,
                    tpo_number: toeflData.tpoNumber,
                    qid: qid,
                    gid: gid,
                    structured: structured
                }
            case 'speaking_generation':
            case 'speaking_generation_task1':
                return {
                    type: 'speaking_generation_task1',
                    topic: toeflData.question,
                    target_score: toeflData.targetScore,
                    outline: toeflData.answerOutline,
                    tpo_number: toeflData.tpoNumber,
                    qid: qid,
                    structured: structured
                }
            case 'speaking_generation_task2':
                return {
                    type: 'speaking_generation_task2',
                    topic: toeflData.question,
                    reading: toeflData.readingText,
                    listening: toeflData.listeningText,
                    target_score: toeflData.targetScore,
                    outline: toeflData.answerOutline,
                    tpo_number: toeflData.tpoNumber,
                    qid: qid,
                    structured: structured
                }
            case 'speaking_generation_task3':
                return {
                    type: 'speaking_generation_task3',
                    topic: toeflData.question,
                    reading: toeflData.readingText,
                    listening: toeflData.listeningText,
                    target_score: toeflData.targetScore,
                    outline: toeflData.answerOutline,
                    tpo_number: toeflData.tpoNumber,
                    qid: qid,
                    structured: structured
                }
            case 'speaking_generation_task4':
                return {
                    type: 'speaking_generation_task4',
                    topic: toeflData.question,
                    listening: toeflData.listeningText,
                    target_score: toeflData.targetScore,
                    outline: toeflData.answerOutline,
                    tpo_number: toeflData.tpoNumber,
                    qid: qid,
                    structured: structured
                }
            case 'writing_task1':
                return {
                    type: 'writing_task1',
                    topic: toeflData.question,
                    content: toeflData.answer,
                    reading: toeflData.readingText,
                    listening: toeflData.listeningText,
                    example_level: toeflData.selectedExampleLevel,
                    tpo_number: toeflData.tpoNumber,
                    qid: qid,
                    gid: gid,
                    structured: structured
                }
            case 'writing_task2':
                return {
                    type: 'writing_task2',
                    topic: toeflData.question,
                    content: toeflData.answer,
                    example_level: toeflData.selectedExampleLevel,
                    tpo_number: toeflData.tpoNumber,
                    qid: qid,
                    gid: gid,
                    structured: structured
                }
            case 'writing_task3':
                return {
                    type: 'writing_task3',
                    topic: toeflData.question,
                    content: toeflData.answer,
                    reading: toeflData.readingText,
                    conversations: toeflData.conversations,
                    example_level: toeflData.selectedExampleLevel,
                    tpo_number: toeflData.tpoNumber,
                    qid: qid,
                    gid: gid,
                    structured: structured
                }
            case 'writing_generation_task1':
                return {
                    type: 'writing_generation_task1',
                    topic: toeflData.question,
                    reading: toeflData.readingText,
                    listening: toeflData.listeningText,
                    target_score: toeflData.targetScore,
                    outline: toeflData.answerOutline,
                    tpo_number: toeflData.tpoNumber,
                    qid: qid,
                    structured: structured
                }
            case 'writing_generation_task2':
                return {
                    type: 'writing_generation',
                    topic: toeflData.question,
                    target_score: toeflData.targetScore,
                    outline: toeflData.answerOutline,
                    tpo_number: toeflData.tpoNumber,
                    qid: qid,
                    structured: structured
                }
            case 'writing_generation_task3':
                return {
                    type: 'writing_generation_task3',
                    topic: toeflData.question,
                    conversations: toeflData.conversations,
                    target_score: toeflData.targetScore,
                    outline: toeflData.answerOutline,
                    tpo_number: toeflData.tpoNumber,
                    qid: qid,
                    structured: structured
                }
        }
    };

    const clearSavedContent = () => {
        if (toeflData.section === 'speaking') {
            const key = `toefl_audio_${qid}`;
            localStorage.removeItem(key);
            console.log("Cleared saved audio content for key:", key);
        } else {
            const key = `toefl_answer_${qid}`;
            localStorage.removeItem(key);
            console.log("Cleared saved answer content for key:", key);
        }
    };

    const handleSubmit = () => {
        mixpanel.track('Submit Button Click', {
            project: 'toefl',
            genType: genType,
            qid: qid,
            gid: gid,
            structured: structured,
        });
        const params = prepareRequestParams();
        console.log("prepared params", params)
        if (!params) {
            return;
        }

        if (shouldShowChargeAlert() && silenceChargeAlert) {
            let prefs = {};
            prefs[getChargeAlertKey()] = true;
            updateUserPreferencesRequest(prefs)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                })
                .catch(console.error);
        }

        let startTime = Math.floor(Date.now());

        dispatch({
            type: `${cmApi.reducerPath}/invalidateTags`,
            payload: ['History'],
        });

        dispatch(updateIsStreaming(true));

        params['authorization'] = AuthService.getCurrentUser()["token"];
        params['lang'] = i18n.language;

        let url = process.env.REACT_APP_WS_BACKEND_URL ? process.env.REACT_APP_WS_BACKEND_URL : window.location.origin;
        url = url.replace("https://", "wss://").replace("http://", "ws://");
        url = url + "/api/generate_ws";
        const ws = new WebSocket(url);

        ws.onopen = (event) => {
            ws.send(JSON.stringify(params));
        };

        let isCleared = false;
        ws.onmessage = function (event) {
            let data = JSON.parse(event.data);
            dispatch(updateContent(data.c));
            if (data.t === "data") {
                // clear saved content only once
                if (!isCleared) {
                    clearSavedContent();
                    isCleared = true;
                }
                dispatch(appendResponseTrunk(data));
            } else if (data.t === "metadata") {
                dispatch(clearResponse(data.c));
                reportRequest({
                    "type": "start_streaming",
                    "start_time": startTime / 1000,
                    "duration": (Math.floor(Date.now()) - startTime) / 1000,
                    "gid": data.c.gid
                }).then(function (response) { });
            } else if (data.t === "error") {
                dispatch(handleErrorResponse(data));
            }
        };

        ws.onclose = function (event) {
            dispatch(updateIsStreaming(false));
            dispatch({
                type: `${cmApi.reducerPath}/invalidateTags`,
                payload: ['History'],
            });
            reportRequest({
                "type": "finish_streaming",
                "start_time": startTime / 1000,
                "duration": (Math.floor(Date.now()) - startTime) / 1000,
            }).then(function (response) { });
        };

        ws.onerror = function (error) {
            console.log("WebSocket error: ", error);
            reportRequest({
                "type": "error_streaming",
                "start_time": startTime / 1000,
                "duration": (Math.floor(Date.now()) - startTime) / 1000,
                "error": error
            }).then(function (response) { });
        };
    };

    //console.log("genType", genType);

    return <>
        <Button fontSize={'sm'}
            rounded={'full'}
            bg={'blue.400'}
            color={'white'}
            _hover={{
                bg: 'blue.500',
            }}
            _focus={{
                bg: 'blue.500',
            }}
            minWidth={120}
            onClick={() => {
                if (toeflData.isStreaming) {
                    dispatch(abortGeneration());
                } else {
                    const currentTime = new Date().getTime();

                    if (!validateEvaluationInput()) {
                        console.log("Evaluation input is not valid")
                        return;
                    }

                    const lastClickTime = localStorage.getItem('lastClickTime');
                    console.log("time diff", currentTime - lastClickTime);
                    if (lastClickTime && (currentTime - lastClickTime) < 60000) {
                        onSubmitAlertOpen();
                    } else {
                        if (shouldShowChargeAlert()) {
                            onChargeAlertOpen();
                        } else {
                            handleSubmit();
                        }
                    }
                    localStorage.setItem('lastClickTime', currentTime);
                }
            }}
            {...other}
        >{toeflData.isStreaming ? t('stop_record') : (isGenTypeGeneration(genType) ? t('generate') : t('grading'))}</Button>
        {/* <Box hidden="hidden">
            <EssayGeneration content={content} />
        </Box> */}
        <AlertDialog
            isOpen={isChargeAlertOpen}
            leastDestructiveRef={cancelRef}
            onClose={onChargeAlertClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        {t("kind_notice")}
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        <Text>
                            {t("this_will_cost")} {getChargeAmount(genType)}{t("credit")}
                        </Text>
                        <Text fontSize={'sm'} style={{ color: "#2e8ae6", fontWeight: "bold" }}>
                            <a href='/purchase'>{t("purchase_credits")}</a>
                        </Text>
                        <Checkbox
                            isChecked={silenceChargeAlert}
                            onChange={(e) => setSilenceChargeAlert(e.target.checked)}
                        >{t("dont_remind_me_again")}</Checkbox>

                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onChargeAlertClose}>
                            {t("cancel")}
                        </Button>
                        <Button colorScheme='green' onClick={() => {
                            onChargeAlertClose();
                            handleSubmit();
                        }} ml={3}>
                            {t("continue")}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
        <AlertDialog
            isOpen={isSubmitAlertOpen}
            leastDestructiveRef={cancelRef}
            onClose={onSubmitAlertClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        {t("kind_notice")}
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        <Text>
                            {t("submit_confirm_message")}
                        </Text>

                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onSubmitAlertClose}>
                            {t("cancel")}
                        </Button>
                        <Button colorScheme='green' onClick={() => {
                            onSubmitAlertClose();
                            handleSubmit();
                        }} ml={3}>
                            {t("continue")}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
        </>
}