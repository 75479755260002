import {useTranslation} from "react-i18next";
import {Badge, Box, Button, Center, Flex, Stack, StackDivider, Text} from "@chakra-ui/react";
import {useHistory} from "react-router-dom";
import QuestionCardNewUserPopover from "./QuestionCardNewUserPopover";
import React from "react";

export function QuestionCard(props) {
    var {genType, summary, tags, tpoNumber, title, qid, completed, ...other} = props;
    let section = genType.includes('writing') ? 'writing' : 'speaking';
    let history = useHistory();
    const {t, i18n} = useTranslation();
    const generationTasks = ["speaking_task1", "speaking_task2", "speaking_task3", "speaking_task4", "writing_task1", "writing_task3"]
    const enableGeneration = generationTasks.includes(genType);


    // async function checkCardFreeStatus(id) {
    //     try {
    //         const card = await fetchCard(id);
    //         if (isCardFree(card)) {
    //             console.log('This card is free.');
    //         } else {
    //             console.log('This card is not free.');
    //         }
    //     } catch (error) {
    //         console.error('Error fetching card:', error);
    //     }
    // }


    return (
        <Box
            bg="gray.100"
            borderRadius='20px'
            px={{lg: "20px", xl: "20px", md: "20px", sm: "6px"}}
            pt="20px"
            pb="30px"
            shadow="md"
            height="90%"
            width={{lg: "90%", md: "100%", sm: "100%"}}
        >
            <Flex>
                <Box flex={1}>
                    <Text fontSize="md" fontWeight='bold'>{title}</Text>
                </Box>
                <Center display={completed ? "block" : "none"}>
                    <Badge
                        variant='solid' colorScheme='yellow' py="4px" px="8px" borderRadius="full"
                    >
                        {t("completed")}
                    </Badge>
                </Center>
            </Flex>
            <Stack divider={<StackDivider/>} spacing="4">
                <Box>
                    <Text pt="2" fontSize="sm" isTruncated>
                        {summary && summary.replace(/<br\s*\/?>/gi, ' ')}
                        {/* <div dangerouslySetInnerHTML={{ __html: summary }} /> */}
                    </Text>
                    {tags &&
                        <Stack direction="row" wrap="wrap" spacing={2}>
                            {tags.map(tag => (
                                <Box key={tag.id} p={1}>
                                    <Badge
                                        colorScheme="blue"
                                        fontSize="0.85em"
                                        borderRadius="lg"
                                        px={3}
                                        py={1}
                                        textTransform="none"
                                        boxShadow="sm"
                                    >
                                        <Text isTruncated maxWidth="150px">
                                            {tag[i18n.language]}
                                        </Text>
                                    </Badge>
                                </Box>
                            ))}
                        </Stack>}
                    <Box mt="20px">
                        <Flex gap="10px" direction={{lg: "column", xl: "row", md: "column", sm: "column"}}>
                            <Flex flexDirection="column">
                                <Button value={tpoNumber} colorScheme='blue' size='sm' borderRadius='full' p="15px"
                                        width={{sm: "full"}} onClick={(e) => {
                                    if (genType.startsWith('i')) {
                                        history.push(`/ielts/${section}/practice?qid=${qid}`)
                                    } else {
                                        history.push(`/toefl/${section}/practice?qid=${qid}`)
                                    }
                                }}>
                                    {t("practice_mode")}
                                </Button>
                                <Center>
                                    {
                                        tpoNumber == 1 &&
                                        <QuestionCardNewUserPopover></QuestionCardNewUserPopover>
                                    }
                                </Center>
                            </Flex>
                            <Box display={enableGeneration ? 'block' : 'none'}>
                                <Button
                                    value={tpoNumber} colorScheme='blue' size='sm' borderRadius='full' p="15px"
                                    width={{md: "full", sm: "full"}}
                                    onClick={(e) => {
                                        history.push(`/toefl/${section}/corpus_generation?qid=${qid}`)
                                    }}>
                                    {t("corpus_accumulation")}
                                </Button>
                            </Box>
                            <Box display={genType.startsWith('i') ? "none" : "block"}>
                                <Button value={tpoNumber} colorScheme='blue' size='sm' borderRadius='full' p="15px"
                                        width={{md: "full", sm: "full"}}
                                        onClick={(e) => {
                                            history.push(`/mock_test?section=${section}&task_name=${genType.split("_")[1]}&qid=${qid}`)
                                        }}>
                                    {t("ibt_practice")}
                                </Button>
                            </Box>
                        </Flex>
                    </Box>

                </Box>
            </Stack>

        </Box>
    );
}