import React, { useEffect, useState } from "react";
import { AiFillFire } from "react-icons/ai";
import { FaRegSmile } from "react-icons/fa";
import authHeader from "./services/auth-header";


export default function AiChat(props) {
    const { ...rest } = props;
    const [currentFilter, setFilter] = useState("All Characters");
    const [username, setUsername] = useState("");
    const [characters, setCharacters] = useState([]);

    useEffect(() => {
        const userData = localStorage.getItem("user");

        if (userData) {
            const parsedData = JSON.parse(userData);
            setUsername(parsedData.user_name.split('@')[0]);
        } else {
            console.log("No user data found in localStorage");
        }

        const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;

        fetch(API_URL + '/api/get_characters', {
            headers: authHeader()
        })
            .then(response => response.json())
            .then(data => {
                const formattedCharacters = data.map(char => ({
                    id: char.id,
                    name: char.name,
                    from: char.category === 'harry-potter' ? 'from Harry Potter' : 
                          char.category === 'culture-guides' ? char.name.split(' ')[1] : '',
                    category: char.category === 'harry-potter' ? 'Harry Potter' : 
                             char.category === 'culture-guides' ? 'Culture Guides' : char.category,
                    img: char.avatarURL,
                    desc: char.description,
                    rating: "4.9"
                }));
                setCharacters(formattedCharacters);
            })
            .catch(error => console.error('Error fetching characters:', error));
    }, []);

    function changeFilter(filterName) {
        setFilter(filterName);
    }

    function getFilteredCharacters() {
        if (currentFilter === "All Characters") return characters;
        return characters.filter((item) => item.category.includes(currentFilter));
    }

    const filteredCharacters = getFilteredCharacters();

    const handleChatClick = (characterId) => {
        const currentDomain = window.location.hostname;
        const baseUrl = currentDomain === 'localhost' 
            //? `${window.location.protocol}//${currentDomain}:${window.location.port}` 
            ? 'http://localhost:3000'
            : `https://live.${currentDomain.replace('app.', '')}`;

        window.open(`${baseUrl}/?character=${characterId}`, '_blank');
    };

    return (
        <div className="flex flex-row justify-between h-full mt-4 ml-14">
            <div className="w-3/4 mt-8 mr-2">
                <div className="flex flex-col text-white">
                    <h2 className="text-xl font-bold">Hello, {username}</h2>
                    <p className="text-lg font-extralight">How can I help you today?</p>
                </div>

                {/* filters */}
                <div className="flex gap-x-3 mt-3">
                    <button
                        className={`px-4 py-1 rounded-full font-medium border-2 border-solid border-black transition-colors duration-200 ${currentFilter === "All Characters" ? 'bg-black text-white' : 'bg-white text-black hover:bg-gray-100'
                            }`}
                        onClick={() => changeFilter("All Characters")}
                    >
                        All Characters
                    </button>
                    <button
                        className={`px-4 py-1 rounded-full font-medium border-2 border-solid border-black transition-colors duration-200 ${currentFilter === "Leapy" ? 'bg-black text-white' : 'bg-white text-black hover:bg-gray-100'
                            }`}
                        onClick={() => changeFilter("Leapy")}
                    >
                        Leapy
                    </button>
                    <button
                        className={`px-4 py-1 rounded-full font-medium border-2 border-solid border-black transition-colors duration-200 ${currentFilter === "Teachers" ? 'bg-black text-white' : 'bg-white text-black hover:bg-gray-100'
                            }`}
                        onClick={() => changeFilter("Teachers")}
                    >
                        Teachers
                    </button>
                </div>

                {/* filtered character list */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-10 md:gap-x-[10rem] lg:gap-x-[20rem] mt-3">
                    {filteredCharacters.map((item, index) => (
                        <div key={index} className="bg-[#FFFFFF] p-3 flex flex-col rounded-2xl w-[22rem] h-fit pb-3 hover:bg-[#A3A4FF] hover:cursor-pointer transition-all duration-200">
                            <span className="flex items-center">
                                <h2 className="font-extrabold">{item.name}</h2>{`, ${item.from}`}
                            </span>
                            <div className="p-2 flex justify-center">
                                <img 
                                    src={item.img} 
                                    alt={`${item.name}`} 
                                    className="h-[210px] w-[256px] object-cover rounded-lg"
                                />
                            </div>
                            <p>{item.desc}</p>
                            <div className="flex flex-row justify-between items-center mt-auto">
                                <span className="flex items-center gap-x-2">
                                    {item.rating === "5.0" ? (
                                        <AiFillFire className="text-red-700" />
                                    ) : parseFloat(item.rating) > 4.7 && parseFloat(item.rating) <= 4.9 ? (
                                        <FaRegSmile />
                                    ) : null}
                                    <p>{item.rating}</p>
                                </span>
                                <button className="p-2 hover:bg-black hover:text-white font-bold border-2 border-solid border-black rounded-lg" onClick={() => handleChatClick(item.id)}>
                                    Chat now
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
